import { useRecoilValue, useResetRecoilState } from 'recoil';

import {
  GetOutpatientQuestionnaireEntryDetailDocument,
  useNotifyOutpatientQuestionnaireEntryMutation,
} from '~/graphql';
import { outpatientQuestionnaireNotifyConfirmModalState } from '~/state/reception/atoms';

export const useNotifyOutpatientQuestionnaireEntry = () => {
  const { outpatientQuestionnaireEntryId } = useRecoilValue(
    outpatientQuestionnaireNotifyConfirmModalState,
  );

  const resetState = useResetRecoilState(outpatientQuestionnaireNotifyConfirmModalState);
  const [notifyOutpatientQuestionnaireEntry, { loading: notifying, error }] =
    useNotifyOutpatientQuestionnaireEntryMutation({
      onCompleted: () => {
        resetState();
      },
      refetchQueries: [
        {
          query: GetOutpatientQuestionnaireEntryDetailDocument,
          variables: { id: outpatientQuestionnaireEntryId },
        },
      ],
    });

  return { notifyOutpatientQuestionnaireEntry, notifying, error };
};
