import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Icon, IconName } from '~/components/blocks';

type ReceptionName = 'checkinEntry' | 'outpatientQuestionnaireEntry';

type Props = {
  receptionType: ReceptionName;
};

const Root = styled(Box)(({ theme }) =>
  css({
    display: 'flex',
    backgroundColor: theme.colors.background.bg,
    borderRadius: theme.radii.default,
    padding: theme.space.s,
    marginRight: theme.space.m,
    maxWidth: '28px',
    maxHeight: '28px',
    alignItems: 'center',
  }),
);

const getIconName = (receptionType: ReceptionName): IconName => {
  switch (receptionType) {
    // defaultは一旦 checkin にしておく
    case 'checkinEntry':
      return 'checkin';
    case 'outpatientQuestionnaireEntry':
      return 'document';
    default:
      return 'checkin';
  }
};

export const ReceptionIcon = (props: Props) => {
  const iconName = getIconName(props.receptionType);

  return (
    <Root>
      <Icon icon={iconName} size="l" />
    </Root>
  );
};
