import React, { useCallback } from 'react';

import { Box, EntryList, Flex, Grid, Icon, Text } from '~/components/blocks';
import { MemoField, ReceptionTypeIcon } from '~/components/partials';
import { ImmediateInfo } from '~/components/partials/ImmediateInfo';
import { OutpatientQuestionnaireEntryStatusLabel } from '~/components/partials/OutpatientQuestionnaireEntryStatusLabel';
import { ReceiveOption } from '~/components/partials/ReceiveOption';
import {
  OutpatientQuestionnaireEntryDetailFragment,
  OutpatientQuestionnaireEntryStatus,
} from '~/graphql';
import { theme } from '~/styles/theme';
import { Label } from '~/utils/label';

import { OutpatientQuestionnaireEntryConfirmationInfo } from './OutpatientQuestionnaireEntryConfirmationInfo';
import { useUpdateMemo } from './use-update-memo';

type Props = {
  outpatientQuestionnaireEntry: OutpatientQuestionnaireEntryDetailFragment;
};

export const ProfilePane = React.memo((props: Props) => {
  const { outpatientQuestionnaireEntry } = props;
  const directVisitor = outpatientQuestionnaireEntry.directVisitor;
  const enteredAt = outpatientQuestionnaireEntry.enteredAt;
  const enteredEndAt = new Date(+new Date(enteredAt) + 30 * 60 * 1000);

  const { called, updating, latestMemo, update } = useUpdateMemo(outpatientQuestionnaireEntry.id);

  const handleChangeMemo = useCallback((memo: string) => update(memo), [update]);

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      <Grid
        gridTemplateColumns="min-content auto"
        gridTemplateRows="50px min-content"
        alignItems="center"
      >
        <Flex justifyContent="center" alignItems="center" mt={theme.space.s}>
          <ReceptionTypeIcon size="xxxl" mr="xxs" />
        </Flex>
        <Flex justifyItems="start" alignItems="center">
          <Text fontWeight="bold" size="m" paddingLeft={theme.space.s}>
            対面服薬指導
          </Text>
          <Box marginLeft="auto">
            <OutpatientQuestionnaireEntryStatusLabel
              enableNotification={outpatientQuestionnaireEntry.enableNotification}
              status={outpatientQuestionnaireEntry.status}
            />
          </Box>
        </Flex>
        <Flex justifyContent="center">
          <Icon icon="time" size="xl" />
        </Flex>
        <Flex justifyItems="start">
          <Text fontWeight="bold" size="m" ml={theme.space.s}>
            {Label.YYYYMMDDja(enteredAt)}
          </Text>
          <Text fontWeight="bold" size="m" ml={theme.space.s}>
            {Label.HHMM(enteredAt)}-{Label.HHMM(enteredEndAt)}
          </Text>
        </Flex>
      </Grid>
      <ImmediateInfo text="準備ができ次第受け取り希望" />
      <ReceiveOption deliveryMethod="hand" />
      {outpatientQuestionnaireEntry.status === OutpatientQuestionnaireEntryStatus.Entered && (
        <OutpatientQuestionnaireEntryConfirmationInfo
          enableNotification={outpatientQuestionnaireEntry.enableNotification}
        />
      )}
      <Box marginTop={theme.space.l}>
        <EntryList>
          <EntryList.Head>氏名</EntryList.Head>
          <EntryList.Body>
            <Flex alignItems="center">
              {`${directVisitor.familyName} ${directVisitor.givenName}`}
              <Flex alignItems="center" marginLeft={theme.space.m}>
                <Icon icon="document" size="m" />
                Web問診
              </Flex>
            </Flex>
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>氏名（カナ）</EntryList.Head>
          <EntryList.Body>{`${directVisitor.phoneticFamilyName} ${directVisitor.phoneticGivenName}`}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>生年月日</EntryList.Head>
          <EntryList.Body>{Label.warekiBirthDate(directVisitor.birthDate)}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>性別</EntryList.Head>
          <EntryList.Body>{Label.sex(directVisitor.sex)}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>電話番号（携帯）</EntryList.Head>
          <EntryList.Body>{directVisitor.mobilePhone}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>申し込み日時</EntryList.Head>
          <EntryList.Body>
            <Flex>
              <Box>
                <Text size="m">{Label.YYYYMMDDja(enteredAt)}</Text>
                <Text size="m">{Label.HHMM(enteredAt)}</Text>
              </Box>
            </Flex>
          </EntryList.Body>
        </EntryList>
      </Box>
      <EntryList marginTop={theme.space.l}>
        <EntryList.Head>メモ</EntryList.Head>
        <EntryList.Body>
          <MemoField
            disabled={updating}
            value={called ? latestMemo : outpatientQuestionnaireEntry.description || ''}
            onChange={handleChangeMemo}
          />
        </EntryList.Body>
      </EntryList>
    </Box>
  );
});

ProfilePane.displayName = 'ProfilePane';
