import React, { ComponentProps } from 'react';

import { Chip } from '~/components/blocks';
import { ReceptionStatus } from '~/components/partials/ReceptionStatus';
import { OutpatientQuestionnaireEntryStatus } from '~/graphql';

type Props = {
  size?: ComponentProps<typeof Chip>['size'];
  enableNotification: boolean;
  status: OutpatientQuestionnaireEntryStatus;
};

export const OutpatientQuestionnaireEntryStatusLabel = (props: Props) => {
  const { enableNotification, status } = props;

  if (status === OutpatientQuestionnaireEntryStatus.Finished) {
    return <ReceptionStatus label="完了" chipColor="grey03" labelColor="black" />;
  }

  if (enableNotification) {
    return (
      <ReceptionStatus
        label="指導待ち"
        chipColor="blue"
        labelColor="white"
        detailedStatus={{
          label: status === OutpatientQuestionnaireEntryStatus.Entered ? '未通知' : '通知済',
          color: status === OutpatientQuestionnaireEntryStatus.Entered ? 'pink' : 'green',
          iconName: 'notification',
        }}
      />
    );
  } else {
    return <ReceptionStatus label="指導待ち" chipColor="blue" labelColor="white" />;
  }
};
