import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetOutpatientQuestionnaireEntryDetailLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { outpatientQuestionnaireNotifyConfirmModalState } from '~/state/reception/atoms';

export const useFetchOutpatientQuestionnaireEntry = () => {
  const { isOpen, outpatientQuestionnaireEntryId } = useRecoilValue(
    outpatientQuestionnaireNotifyConfirmModalState,
  );
  const [getOutpatientQuestionnaireEntry, { data, loading, error }] =
    useGetOutpatientQuestionnaireEntryDetailLazyQuery({
      fetchPolicy: 'network-only',
    });

  const outpatientQuestionnaireEntry = getNode(data, 'OutpatientQuestionnaireEntry');

  useEffect(() => {
    if (isOpen && outpatientQuestionnaireEntryId) {
      getOutpatientQuestionnaireEntry({
        variables: {
          id: outpatientQuestionnaireEntryId,
        },
      });
    }
  }, [getOutpatientQuestionnaireEntry, isOpen, outpatientQuestionnaireEntryId]);

  return { outpatientQuestionnaireEntry, loading, error };
};
