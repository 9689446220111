import { useCallback, useEffect, useState } from 'react';

import { useUpdateOutpatientQuestionnaireEntryDescriptionMutation } from '~/graphql';

export const useUpdateMemo = (outpatientQuestionnaireEntryId: string) => {
  const [latestMemo, setLatestMemo] = useState(String);

  const [updateMemo, { called, error, loading: updating }] =
    useUpdateOutpatientQuestionnaireEntryDescriptionMutation({
      onCompleted: (_data) => {
        // Apollo or useMemo のキャッシュまわりの不整合が発生するので、メモ更新後は Mutation の返り値を使う
        // FYI https://github.com/medley-inc/pharms/issues/1852
        const latestDescription =
          _data.updateOutpatientQuestionnaireDescription?.outpatientQuestionnaireEntry
            .description || '';

        // updateDescriptionData はリロード走るまで値を保持し続けるので、別の受付ドロワーを開いたタイミングでリセットできるようにステート管理する
        setLatestMemo(latestDescription);
      },
    });

  const update = useCallback(
    async (memo: string) => {
      try {
        await updateMemo({
          variables: {
            input: {
              outpatientQuestionnaireEntryId,
              description: memo,
            },
          },
        });
      } catch {
        // 何もしない
      }
    },
    [outpatientQuestionnaireEntryId, updateMemo],
  );

  useEffect(() => {
    setLatestMemo('');
  }, [outpatientQuestionnaireEntryId]);

  return {
    called,
    error,
    updating,
    latestMemo,
    update,
  };
};
