import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  GetOutpatientQuestionnaireEntryDetailDocument,
  useFinishOutpatientQuestionnaireEntryMutation,
} from '~/graphql';
import { followupMessageSettingModalState } from '~/state/partials/followup_message_setting_modal/atoms';
import {
  outpatientQuestionnaireEntryProfileDrawerState,
  outpatientQuestionnaireFinishConfirmModalModalState,
  outpatientQuestionnaireNotifyConfirmModalState,
} from '~/state/reception/atoms';

export const useFinishOutpatientQuestionnaireEntry = () => {
  const { outpatientQuestionnaireEntryId } = useRecoilValue(
    outpatientQuestionnaireEntryProfileDrawerState,
  );

  const resetState = useResetRecoilState(outpatientQuestionnaireFinishConfirmModalModalState);
  const resetFinishConfirmModalState = useResetRecoilState(
    outpatientQuestionnaireNotifyConfirmModalState,
  );
  const setFollowupModalState = useSetRecoilState(followupMessageSettingModalState);

  const [finishOutpatientQuestionnaireEntry, { loading: finishing, error }] =
    useFinishOutpatientQuestionnaireEntryMutation({
      onCompleted: (data) => {
        const directVisitorId =
          data.finishOutpatientQuestionnaireEntry?.outpatientQuestionnaireEntry.directVisitor.id ??
          null;
        resetState();
        resetFinishConfirmModalState();
        setFollowupModalState((_state) => ({
          ..._state,
          isOpen: true,
          guestPatientId: directVisitorId,
        }));
      },
      refetchQueries: [
        {
          query: GetOutpatientQuestionnaireEntryDetailDocument,
          variables: { id: outpatientQuestionnaireEntryId },
        },
      ],
    });

  return { finishOutpatientQuestionnaireEntry, finishing, error };
};
