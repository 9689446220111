import React from 'react';

import { Drawer } from '~/components/blocks';
import {
  OutpatientQuestionnaireEntryDetailFragment,
  OutpatientQuestionnaireEntryStatus,
} from '~/graphql';

import { BookedButtons } from './BookedButtons';

type Props = {
  outpatientQuestionnaireEntry?: OutpatientQuestionnaireEntryDetailFragment;
};

export const Footer = React.memo((props: Props) => {
  const { outpatientQuestionnaireEntry } = props;
  const status = outpatientQuestionnaireEntry?.status;
  const isFinished = status === OutpatientQuestionnaireEntryStatus.Finished;

  if (!outpatientQuestionnaireEntry || isFinished) {
    return null;
  }

  return (
    <Drawer.Footer>
      <BookedButtons outpatientQuestionnaireEntry={outpatientQuestionnaireEntry} />
    </Drawer.Footer>
  );
});

Footer.displayName = 'Footer';
