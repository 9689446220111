import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Button, Flex } from '~/components/blocks';
import {
  OutpatientQuestionnaireEntryDetailFragment,
  OutpatientQuestionnaireEntryStatus,
} from '~/graphql';
import {
  outpatientQuestionnaireFinishConfirmModalModalState,
  outpatientQuestionnaireNotifyConfirmModalState,
} from '~/state/reception/atoms';

import { useFinishOutpatientQuestionnaireEntry } from '../FinishConfirmModal/use-finish_outpatient_quetionnaire_entry';

type Props = {
  outpatientQuestionnaireEntry: OutpatientQuestionnaireEntryDetailFragment;
};

const ActionButton = styled(Button)(() =>
  css({
    flexBasis: '70%',
  }),
);

const FinishButton = styled(Button)<{ full: boolean }>(({ full }) =>
  css({
    flexBasis: full ? '100%' : '30%',
  }),
);

export const BookedButtons = (props: Props) => {
  const theme = useTheme();
  const { outpatientQuestionnaireEntry } = props;
  const isEntered =
    outpatientQuestionnaireEntry.status === OutpatientQuestionnaireEntryStatus.Entered;

  const { finishOutpatientQuestionnaireEntry, finishing } = useFinishOutpatientQuestionnaireEntry();
  const handleFinishClick = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (outpatientQuestionnaireEntry.enableNotification && isEntered) {
          set(outpatientQuestionnaireFinishConfirmModalModalState, (_state) => ({
            ..._state,
            isOpen: true,
            outpatientQuestionnaireEntryId: outpatientQuestionnaireEntry.id,
          }));
        } else {
          await finishOutpatientQuestionnaireEntry({
            variables: {
              input: {
                outpatientQuestionnaireEntryId: outpatientQuestionnaireEntry.id,
              },
            },
          });
        }
      },
    [
      outpatientQuestionnaireEntry.enableNotification,
      outpatientQuestionnaireEntry.id,
      finishOutpatientQuestionnaireEntry,
      isEntered,
    ],
  );

  const handleNotificationClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(outpatientQuestionnaireNotifyConfirmModalState, (_state) => ({
          ..._state,
          isOpen: true,
          outpatientQuestionnaireEntryId: outpatientQuestionnaireEntry.id,
        }));
      },
    [outpatientQuestionnaireEntry.id],
  );

  if (outpatientQuestionnaireEntry.enableNotification) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <FinishButton
          use="base"
          wide="fill"
          size="l"
          onClick={handleFinishClick}
          full={!isEntered}
          loading={finishing}
        >
          完了
        </FinishButton>
        {isEntered && (
          <ActionButton
            use="primary"
            wide="fill"
            size="l"
            ml={theme.space.m}
            onClick={handleNotificationClick}
          >
            準備完了通知
          </ActionButton>
        )}
      </Flex>
    );
  } else {
    return (
      <Flex alignItems="center" justifyContent="center">
        <FinishButton
          use="base"
          wide="fill"
          size="l"
          onClick={handleFinishClick}
          full={true}
          loading={finishing}
        >
          問診確認完了
        </FinishButton>
      </Flex>
    );
  }
};
