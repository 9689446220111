import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { rgba } from 'polished';

import { Table } from '~/components/blocks';

const animation = keyframes`
    from {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    to {
        opacity: 1;
    }
`;

export const TableRow = styled(Table.Tr, { shouldForwardProp })<{ updated: boolean }>(
  ({ theme, updated }) =>
    css({
      animation: updated ? `${animation} 1s ease 1` : 'none',
      '&:hover': {
        backgroundColor: rgba(theme.colors.background.default, 0.5),
        boxShadow: `inset 3px 0 0 ${theme.colors.background.primary}`,
      },
    }),
);
