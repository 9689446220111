import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  useGetOutpatientQuestionnaireUnreadOrganizationNotificationLazyQuery,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { outpatientQuestionnaireEntryProfileDrawerState } from '~/state/reception/atoms';

export const useMarkNotification = () => {
  const { outpatientQuestionnaireEntryId } = useRecoilValue(
    outpatientQuestionnaireEntryProfileDrawerState,
  );
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const [getNotifications] = useGetOutpatientQuestionnaireUnreadOrganizationNotificationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const notifications =
        getNode(_data, 'OutpatientQuestionnaireEntry')?.unreadOrganizationNotifications || [];
      for (const notification of notifications) {
        markNotification({
          variables: {
            input: {
              organizationNotificationId: notification.id,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (outpatientQuestionnaireEntryId) {
      getNotifications({
        variables: {
          outpatientQuestionnaireEntryId,
        },
      });
    }
  }, [outpatientQuestionnaireEntryId, getNotifications]);
};
