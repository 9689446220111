import React from 'react';

import { OutpatientQuestionnaireSheet } from '~/components/partials/OutpatientQuestionnaireSheet';
import { OutpatientQuestionnaireEntryDetailFragment } from '~/graphql';

type Props = {
  outpatientQuestionnaireEntry: OutpatientQuestionnaireEntryDetailFragment;
};

export const QuestionnairePane = React.memo((props: Props) => {
  const { outpatientQuestionnaireEntry } = props;

  return (
    <OutpatientQuestionnaireSheet
      enableCopy
      answers={outpatientQuestionnaireEntry.questionnaires}
      enteredAt={outpatientQuestionnaireEntry.enteredAt}
    />
  );
});

QuestionnairePane.displayName = 'QuestionnairePane';
