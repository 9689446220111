import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Drawer, Grid, Loader } from '~/components/blocks';
import { VisitorProfile } from '~/components/partials/VisitorProfile';
import { useSwapDrawer } from '~/hooks/use-swap-drawer';
import { outpatientQuestionnaireEntryProfileDrawerState } from '~/state/reception/atoms';

import { FinishConfirmModal } from './FinishConfirmModal';
import { Footer } from './Footer';
import { NotificationConfirmModal } from './NotificationConfirmModal';
import { OutpatientQuestionnaireEntryKarte } from './OutpatientQuestionnaireEntryKarte';
import { useFetchOutpatientQuestionnaireEntry } from './use-fetch_outpatient_questionnaire_entry';
import { useMarkNotification } from './use-mark-notification';

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const OutpatientQuestionnaireEntryProfileDrawer = () => {
  const {
    state: { isOpen },
    handleTransitionEnd,
  } = useSwapDrawer(
    outpatientQuestionnaireEntryProfileDrawerState,
    'outpatientQuestionnaireEntryId',
  );

  const { outpatientQuestionnaireEntry, loading } = useFetchOutpatientQuestionnaireEntry();
  useMarkNotification();

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(outpatientQuestionnaireEntryProfileDrawerState);
      },
    [],
  );

  return (
    <>
      <Drawer open={isOpen} onClose={handleClose} onTransitionEnd={handleTransitionEnd}>
        <Box position="relative" overflow="hidden" height="100%">
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <VisitorProfile
                loading={loading}
                visitor={outpatientQuestionnaireEntry?.directVisitor}
                address={outpatientQuestionnaireEntry?.address}
              />
            </ProfileBox>
            {outpatientQuestionnaireEntry ? (
              <Box overflow="auto">
                <OutpatientQuestionnaireEntryKarte
                  outpatientQuestionnaireEntry={outpatientQuestionnaireEntry}
                />
              </Box>
            ) : (
              <Loader inside open />
            )}
            <Footer outpatientQuestionnaireEntry={outpatientQuestionnaireEntry} />
          </Grid>
        </Box>
      </Drawer>
      <NotificationConfirmModal />
      <FinishConfirmModal />
    </>
  );
};
