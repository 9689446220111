import React, { useCallback, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { DateLabel, Flex, Name, Table, Tag, Text } from '~/components/blocks';
import {
  AppointmentStatusLabel,
  isMedicatoinFollowupTag,
  MedicatoinFollowupTag,
  ReceptionTypeIcon,
} from '~/components/partials';
import { DeliveryMethodField } from '~/components/partials/DeliveryMethodField';
import { ReceptionListAppointmentFragment } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  guestPatientProfileDrawerState,
  newReceptionDrawerState,
  outpatientQuestionnaireEntryProfileDrawerState,
  receptionPageInfoState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

import { TableRow } from './TableRow';

type Props = {
  activeMedixs: boolean;
  appointment: ReceptionListAppointmentFragment;
  updated: boolean;
  onAnimationEnd: (appointmentId: string) => void;
};

const formatAppointmentData = (appointment: ReceptionListAppointmentFragment) => {
  const webVisitor = appointment.webVisitor;

  return {
    id: appointment.id,
    receptAt: appointment.createdAt,
    status: appointment.status,
    patientId: webVisitor?.id,
    familyName: webVisitor?.familyName,
    givenName: webVisitor?.givenName,
    phoneticFamilyName: webVisitor?.phoneticFamilyName,
    phoneticGivenName: webVisitor?.phoneticGivenName,
    birthDate: webVisitor?.birthDate,
    sex: webVisitor?.sex,
    start: appointment.start,
    end: appointment.end,
    memo: appointment.description,
  };
};

export const WebAppointment = React.memo((props: Props) => {
  const { onAnimationEnd } = props;
  const { date } = useRecoilValue(receptionPageInfoState);
  const { enable: enabledUber } = useUberOrganizationSetting();

  const appointment = formatAppointmentData(props.appointment);
  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        set(webVisitorProfileDrawerState, {
          isOpen: true,
          webVisitorId: appointment.patientId as string,
          appointmentId: appointment.id,
        });
        reset(draftAppointmentPatientProfileDrawerState);
        reset(profileDrawerState);
        reset(guestPatientProfileDrawerState);
        reset(newReceptionDrawerState);
        reset(checkinEntryProfileDrawerState);
        reset(outpatientQuestionnaireEntryProfileDrawerState);
      },
    [appointment.id, appointment.patientId],
  );
  const memo = useMemo(() => appointment.memo.split(',').filter(Boolean), [appointment.memo]);
  const handleAnimationEnd = useCallback(
    () => onAnimationEnd(props.appointment.id),
    [onAnimationEnd, props.appointment.id],
  );

  return (
    <TableRow updated={props.updated} onClick={handleClick} onAnimationEnd={handleAnimationEnd}>
      <Table.Td nowrap>
        <Flex alignItems="center">
          {appointment.start && appointment.end && (
            <DateLabel>
              {!date && <DateLabel.Time>{Label.YYYYMMDDja(appointment.start)}</DateLabel.Time>}
              <DateLabel.Day>
                {Label.HHMM(appointment.start)}-{Label.HHMM(appointment.end)}
              </DateLabel.Day>
            </DateLabel>
          )}
        </Flex>
      </Table.Td>
      <Table.Td>
        <AppointmentStatusLabel status={props.appointment.status} />
      </Table.Td>
      {props.activeMedixs && <Table.Td nowrap />}
      <Table.Td nowrap>
        <Name
          familyName={appointment.familyName}
          givenName={appointment.givenName}
          phoneticFamilyName={appointment.phoneticFamilyName}
          phoneticGivenName={appointment.phoneticGivenName}
        />
      </Table.Td>
      <Table.Td nowrap>
        <Text size="m" whiteSpace="nowrap">
          {appointment.birthDate ? Label.age(appointment.birthDate) : ''}
        </Text>
        <Text size="s" whiteSpace="nowrap">
          （{appointment.sex ? Label.sex(appointment.sex) : ''}）
        </Text>
      </Table.Td>
      <Table.Td>
        <Flex alignItems="center">
          <ReceptionTypeIcon />
          <Text fontWeight="bold" size="m" whiteSpace="nowrap">
            対面
          </Text>
        </Flex>
      </Table.Td>
      <Table.Td>
        <DeliveryMethodField deliveryMethod="hand" size="m" />
      </Table.Td>
      {enabledUber && <Table.Td>-</Table.Td>}
      <Table.Td>
        <Text size="m" whiteSpace="nowrap">
          {Label.YYYYMMDDja(appointment.receptAt)}&nbsp;{Label.HHMM(appointment.receptAt)}
        </Text>
      </Table.Td>
      <Table.Td>
        {memo.map((memo, idx) =>
          isMedicatoinFollowupTag(memo) ? (
            <MedicatoinFollowupTag key={idx} text={memo} />
          ) : (
            <Tag key={idx} whiteSpace="nowrap">
              {memo}
            </Tag>
          ),
        )}
      </Table.Td>
    </TableRow>
  );
});

WebAppointment.displayName = 'WebAppointment';
