import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Alert, Button, EntryList, Modal, Text } from '~/components/blocks';
import { DirectVisitorOfOutpatientQuestionnaireEntryDetailFragment } from '~/graphql';
import { getOrganizationName } from '~/localstrage/organization_name';
import { outpatientQuestionnaireNotifyConfirmModalState } from '~/state/reception/atoms';

import { useFetchOutpatientQuestionnaireEntry } from './use-fetch_outpatient_questionnaire_entry';
import { useNotifyOutpatientQuestionnaireEntry } from './use-notify_outpatient_questionnaire_entry';

const Message = styled('header')(({ theme }) =>
  css({
    whiteSpace: 'pre-wrap',
    background: theme.colors.background.bg,
    padding: theme.space.l,
  }),
);

const getPatientName = (
  directVisitor?: DirectVisitorOfOutpatientQuestionnaireEntryDetailFragment,
) => {
  if (!directVisitor) return '';
  const { familyName, givenName } = directVisitor;
  return `${familyName}${givenName}`;
};

export const NotificationConfirmModal = () => {
  const theme = useTheme();
  const { isOpen, outpatientQuestionnaireEntryId } = useRecoilValue(
    outpatientQuestionnaireNotifyConfirmModalState,
  );
  const resetModalState = useResetRecoilState(outpatientQuestionnaireNotifyConfirmModalState);

  const { outpatientQuestionnaireEntry, loading, error } = useFetchOutpatientQuestionnaireEntry();
  const directVisitor = outpatientQuestionnaireEntry?.directVisitor;
  const {
    notifyOutpatientQuestionnaireEntry,
    notifying,
    error: notifyError,
  } = useNotifyOutpatientQuestionnaireEntry();

  const patientName = getPatientName(directVisitor);
  const organizationName = getOrganizationName();
  const content = `${patientName}様\n\n${organizationName}です。お薬の受け渡し準備が出来ましたので、薬局にお越しください。`;

  const handleSend = useCallback(async () => {
    if (!outpatientQuestionnaireEntryId) return;

    try {
      await notifyOutpatientQuestionnaireEntry({
        variables: {
          input: {
            outpatientQuestionnaireEntryId: outpatientQuestionnaireEntryId,
          },
        },
      });
    } catch {
      // 何もしない
    }
  }, [outpatientQuestionnaireEntryId, notifyOutpatientQuestionnaireEntry]);

  return (
    <Modal open={isOpen} size="s" onClose={resetModalState}>
      <Modal.Header>準備完了通知を送信</Modal.Header>
      <Modal.Body>
        {(error || notifyError) && (
          <Alert mb={theme.space.l}>エラーが発生したため送信できませんでした</Alert>
        )}
        <Text>メッセージ内容を確認のうえ、「送信」ボタンをクリックしてください。</Text>
        <EntryList mt={theme.space.m}>
          <EntryList.Head>送信メッセージ</EntryList.Head>
          <EntryList.Body>
            <Message>{content}</Message>
          </EntryList.Body>
        </EntryList>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={loading || notifying} onClick={handleSend}>
          送信
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
