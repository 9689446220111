import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Alert, Button, Loader, Modal, Name, Table, Text } from '~/components/blocks';
import { outpatientQuestionnaireFinishConfirmModalModalState } from '~/state/reception/atoms';
import { Label } from '~/utils/label';

import { useFetchOutpatientQuestionnaireEntry } from '../use-fetch_outpatient_questionnaire_entry';
import { useFinishOutpatientQuestionnaireEntry } from './use-finish_outpatient_quetionnaire_entry';

const PatientInformation = styled('div')(({ theme }) =>
  css({
    width: '100%',
    backgroundColor: theme.colors.background.bg,
    padding: theme.space.m,
    marginTop: theme.space.l,
  }),
);

export const FinishConfirmModal = () => {
  const theme = useTheme();
  const { isOpen, outpatientQuestionnaireEntryId } = useRecoilValue(
    outpatientQuestionnaireFinishConfirmModalModalState,
  );
  const resetState = useResetRecoilState(outpatientQuestionnaireFinishConfirmModalModalState);
  const { finishOutpatientQuestionnaireEntry, finishing, error } =
    useFinishOutpatientQuestionnaireEntry();
  const { outpatientQuestionnaireEntry } = useFetchOutpatientQuestionnaireEntry();
  const directVisitor = outpatientQuestionnaireEntry?.directVisitor;

  const handleFinished = useCallback(async () => {
    if (!outpatientQuestionnaireEntryId) return;

    try {
      await finishOutpatientQuestionnaireEntry({
        variables: {
          input: {
            outpatientQuestionnaireEntryId: outpatientQuestionnaireEntryId,
          },
        },
      });
    } catch {
      // 何もしない
    }
  }, [outpatientQuestionnaireEntryId, finishOutpatientQuestionnaireEntry]);

  return (
    <Modal open={isOpen} size="s" onClose={resetState}>
      <Modal.Header>Web問診対応完了確認</Modal.Header>
      <Modal.Body>
        {error && (
          <Alert status="error" mb={theme.space.l}>
            {error.message}
          </Alert>
        )}
        {outpatientQuestionnaireEntry ? (
          <>
            患者へ通知を行う前に完了すると通知できなくなります。
            <br />
            本当に完了しますか？
            <PatientInformation>
              <Table appearance="noline" whiteSpace="nowrap">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>問診回答日時</Table.Th>
                    <Table.Th>患者名</Table.Th>
                    <Table.Th>電話番号</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>
                      <Text block size="s" fontWeight="bold" whiteSpace="nowrap">
                        {Label.HHMM(outpatientQuestionnaireEntry.enteredAt)}
                      </Text>
                      <Text block size="xs" whiteSpace="nowrap">
                        {Label.YYYYMMDDja(outpatientQuestionnaireEntry.enteredAt)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Name {...directVisitor} size="s" />
                    </Table.Td>
                    <Table.Td>{directVisitor?.mobilePhone}</Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </PatientInformation>
          </>
        ) : (
          <Loader open inside />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" loading={finishing} onClick={handleFinished}>
          完了
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
