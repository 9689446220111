import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Text } from '~/components/blocks';

type Props = {
  enableNotification: boolean;
};

export const OutpatientQuestionnaireEntryConfirmationInfo = React.memo((props: Props) => {
  const { enableNotification } = props;
  const theme = useTheme();

  const message = enableNotification
    ? 'Web問診の回答内容をご確認の上、お薬の準備ができたら、準備完了通知を送って患者にお知らせください'
    : 'Web問診の回答内容をご確認の上、お薬の準備ができたら、薬局内にて患者をお呼び出しください';

  return (
    <Alert status="info" marginTop={theme.space.m}>
      <Text>{message}</Text>
    </Alert>
  );
});

OutpatientQuestionnaireEntryConfirmationInfo.displayName =
  'OutpatientQuestionnaireEntryConfirmationInfo';
