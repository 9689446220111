import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { ScrollBox } from '~/components/blocks';
import {
  ReceptionCheckinEntryFragment,
  ReceptionDraftAppointmentFragment,
  ReceptionListAppointmentFragment,
  ReceptionOutpatientQuestionnaireEntryFragment,
  ReceptionVisitFragment,
  useGetReceptionListAppointmentLazyQuery,
  useGetReceptionListCheckinEntryLazyQuery,
  useGetReceptionListDraftAppointmentLazyQuery,
  useGetReceptionListOutpatientQuestionnaireEntryLazyQuery,
  useGetReceptionsLazyQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { receptionPageInfoState } from '~/state/reception/atoms';
import { formatISOString } from '~/utils/date';

export const useFetchReceptions = () => {
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const [appointmentEvent, clearAppointmentEvent] = useSubscriptions('Appointment', [
    'created',
    'deleted',
    'updated',
  ]);
  const [checkinEntryEvent, clearCheckinEntryEvent] = useSubscriptions('CheckinEntry', [
    'created',
    'updated',
  ]);
  const [outpatientQuestionnaireEntryEvent, clearOutpatientQuestionnaireEntryEvent] =
    useSubscriptions('OutpatientQuestionnaireEntry', ['created', 'updated']);
  const [draftAppointmentEvent, clearDraftAppointmentEvent] = useSubscriptions('DraftAppointment', [
    'created',
    'updated',
  ]);

  const [visibleLoading, setVisibleLoading] = useState(true);
  const [updatedAppointmentIds, setUpdatedAppointmentIds] = useState<string[]>([]);
  const [updatedVisitIds, setUpdatedVisitIds] = useState<string[]>([]);
  const [updatedCheckinEntryIds, setUpdatedCheckinEntryIds] = useState<string[]>([]);
  const [updatedOutpatientQuestionnaireEntryIds, setUpdatedOutpatientQuestionnaireEntryIds] =
    useState<string[]>([]);
  const [updatedDraftAppointmentIds, setUpdatedDraftAppointmentIds] = useState<string[]>([]);
  const [pageInfo, setPageInfo] = useRecoilState(receptionPageInfoState);

  const [getReceptions, { data }] = useGetReceptionsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      setPageInfo((state) => ({
        ...state,
        totalPage: getMe(_data)?.organization.receptions.pagesCount || 1,
      }));
      setVisibleLoading(false);
    },
  });

  const [getAppointment] = useGetReceptionListAppointmentLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [getCheckinEntry] = useGetReceptionListCheckinEntryLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [getOutpatientQuestionnaireEntry] =
    useGetReceptionListOutpatientQuestionnaireEntryLazyQuery({
      fetchPolicy: 'network-only',
    });
  const [getDraftAppointment] = useGetReceptionListDraftAppointmentLazyQuery({
    fetchPolicy: 'network-only',
  });

  const prevData = usePreviousValue(data, true);
  const currentData = data || prevData;
  const receptions = useMemo(
    () => (currentData ? getMe(currentData)?.organization.receptions.nodes || [] : []),
    [currentData],
  );
  const eventAppointmentExists =
    !!appointmentEvent && receptions.some(({ id }) => id === appointmentEvent.typeId);
  const eventCheckinEntryExists =
    !!checkinEntryEvent && receptions.some(({ id }) => id === checkinEntryEvent.typeId);
  const eventOutpatientQuestionnaireEntryExists =
    !!outpatientQuestionnaireEntryEvent &&
    receptions.some(({ id }) => id === outpatientQuestionnaireEntryEvent.typeId);
  const eventDraftAppointmentExists =
    !!draftAppointmentEvent && receptions.some(({ id }) => id === draftAppointmentEvent.typeId);

  const fetchReceptions = useRecoilCallback(
    () => (isScrollToTop: boolean) => {
      getReceptions({
        variables: {
          date: pageInfo.date ? formatISOString(pageInfo.date) : null,
          page: pageInfo.page,
          patientName: pageInfo.patientName,
          perPage: pageInfo.perPage,
          orderBy: pageInfo.orderBy,
          statuses: pageInfo.statuses.length > 0 ? pageInfo.statuses : null,
        },
      });

      if (isScrollToTop) {
        scrollRef.current?.toTop();
      }
    },
    [
      getReceptions,
      pageInfo.date,
      pageInfo.orderBy,
      pageInfo.page,
      pageInfo.patientName,
      pageInfo.perPage,
      pageInfo.statuses,
    ],
  );

  // 連続して検索条件が変化した時に都度リクエストしないようにする
  const fetchReceptionsDebounce = useDebouncedCallback(fetchReceptions, 300);

  const handleAnimationEnd = useCallback((appointmentId: string) => {
    setUpdatedAppointmentIds((_updatedAppointmentIds) =>
      _updatedAppointmentIds.filter((id) => id !== appointmentId),
    );
  }, []);
  const handleAnimationVisitEnd = useCallback((visitId: string) => {
    setUpdatedVisitIds((_updatedVisitIds) => _updatedVisitIds.filter((id) => id !== visitId));
  }, []);

  useEffect(() => {
    // フィルターが変更されたら取得
    setVisibleLoading(true);
    fetchReceptionsDebounce(true);
    setUpdatedAppointmentIds([]);
    clearAppointmentEvent();
    clearCheckinEntryEvent();
    clearOutpatientQuestionnaireEntryEvent();
    clearDraftAppointmentEvent();
  }, [
    fetchReceptionsDebounce,
    pageInfo.date,
    pageInfo.page,
    pageInfo.patientName,
    pageInfo.perPage,
    pageInfo.statuses,
    pageInfo.orderBy,
    clearAppointmentEvent,
    clearCheckinEntryEvent,
    clearOutpatientQuestionnaireEntryEvent,
    clearDraftAppointmentEvent,
  ]);

  useEffect(() => {
    if (!appointmentEvent) return;

    // イベントが作成・削除だったらリスト取得
    if (['created', 'deleted'].includes(appointmentEvent.actionName)) {
      fetchReceptions(false);
    }

    if (appointmentEvent?.actionName === 'updated' && eventAppointmentExists) {
      setUpdatedAppointmentIds((_newAppointmentIds) => {
        return [...new Set([..._newAppointmentIds, appointmentEvent.typeId])];
      });
      getAppointment({ variables: { appointmentId: appointmentEvent.typeId } });
    }
  }, [appointmentEvent, eventAppointmentExists, fetchReceptions, getAppointment]);

  useEffect(() => {
    if (!checkinEntryEvent) return;

    // イベントが作成だったらリスト取得
    if (['created'].includes(checkinEntryEvent.actionName)) {
      fetchReceptions(false);
    }

    if (checkinEntryEvent?.actionName === 'updated' && eventCheckinEntryExists) {
      setUpdatedCheckinEntryIds((_ids) => {
        return [...new Set([..._ids, checkinEntryEvent.typeId])];
      });
      getCheckinEntry({ variables: { checkinEntryId: checkinEntryEvent.typeId } });
    }
  }, [eventCheckinEntryExists, fetchReceptions, checkinEntryEvent, getCheckinEntry]);

  useEffect(() => {
    if (!outpatientQuestionnaireEntryEvent) return;

    // イベントが作成だったらリスト取得
    if (['created'].includes(outpatientQuestionnaireEntryEvent.actionName)) {
      fetchReceptions(false);
    }

    if (
      outpatientQuestionnaireEntryEvent?.actionName === 'updated' &&
      eventOutpatientQuestionnaireEntryExists
    ) {
      setUpdatedOutpatientQuestionnaireEntryIds((_ids) => {
        return [...new Set([..._ids, outpatientQuestionnaireEntryEvent.typeId])];
      });
      getOutpatientQuestionnaireEntry({
        variables: { outpatientQuestionnaireEntryId: outpatientQuestionnaireEntryEvent.typeId },
      });
    }
  }, [
    eventOutpatientQuestionnaireEntryExists,
    fetchReceptions,
    outpatientQuestionnaireEntryEvent,
    getOutpatientQuestionnaireEntry,
  ]);

  useEffect(() => {
    if (!draftAppointmentEvent) return;

    // イベントが作成だったらリスト取得
    if (['created'].includes(draftAppointmentEvent.actionName)) {
      fetchReceptions(false);
    }

    if (draftAppointmentEvent?.actionName === 'updated' && eventDraftAppointmentExists) {
      setUpdatedDraftAppointmentIds((_ids) => {
        return [...new Set([..._ids, draftAppointmentEvent.typeId])];
      });
      getDraftAppointment({ variables: { draftAppointmentId: draftAppointmentEvent.typeId } });
    }
  }, [eventDraftAppointmentExists, fetchReceptions, draftAppointmentEvent, getDraftAppointment]);

  useEffect(() => {
    // 新旧リストを比較してステータスが変更されているものがあればリスト再取得をおこなう
    // ステータスフィルタリングによっては不適切なステータスのものが表示されている可能性があるため
    if (data && prevData) {
      const receptions = getMe(data)?.organization.receptions.nodes || [];
      const prevReceptions = getMe(prevData)?.organization.receptions.nodes || [];

      for (
        let i = 0,
          reception:
            | ReceptionListAppointmentFragment
            | ReceptionVisitFragment
            | ReceptionCheckinEntryFragment
            | ReceptionOutpatientQuestionnaireEntryFragment
            | ReceptionDraftAppointmentFragment;
        (reception = receptions[i]);
        ++i
      ) {
        const prevReception = prevReceptions.filter(({ id }) => id === reception.id)[0];

        if (!prevReception) continue;
        if (prevReception.__typename !== 'Appointment') continue;
        if (reception.__typename !== 'Appointment') continue;

        if (prevReception.status !== reception.status) {
          fetchReceptions(false);
          break;
        }
      }
    }
  }, [data, fetchReceptions, prevData]);

  return {
    visibleLoading,
    updatedAppointmentIds,
    updatedVisitIds,
    updatedCheckinEntryIds,
    updatedOutpatientQuestionnaireEntryIds,
    updatedDraftAppointmentIds,
    receptions,
    scrollRef,
    handleAnimationEnd,
    handleAnimationVisitEnd,
  };
};
